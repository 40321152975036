import '../../service/domain/user.js';
import '../../service/domain/achievement.js';
import '../../service/configs/config.js';
import { SteppedEase, TimelineMax, TweenMax } from 'gsap';
(function () {
  'use strict';

  const component = { name: 'lindaCupMobile' };

  controller.$inject = ['$scope', '$q', 'user', '$timeout', 'achievement', 'config'];

  function controller($scope, $q, _user, $timeout, _achievement, _config) {
    $scope.cup = {
      prizeCount: 0,
      goal: 0,
      currentCount: 0,
      currentPtc: 0,
    };

    const cupElement = {
      cup: document.querySelector('.cup'),
      wrap: document.querySelector('.cup__wrap'),
      progress: document.querySelector('.cup__front'),
      mark: document.querySelector('.cup-mark.is-current'),
      back: document.querySelector('.cup__back'),
      front: document.querySelector('.cup__front-img'),
    };

    const progressDuration = 2;

    const progressTimeLine = new TimelineMax({ paused: true });

    const twistDuration = 0.25;
    const twistConfig = {
      reversed: true,
      backgroundPosition: '-11808px 0px',
      ease: SteppedEase.config(36),
    };
    const twistTimeLine = new TimelineMax({ paused: true });
    const twistBackTween = TweenMax.to(cupElement.back, twistDuration, twistConfig);
    const twistFrontTween = TweenMax.to(cupElement.front, twistDuration, twistConfig);

    twistTimeLine.add(twistBackTween, 0);
    twistTimeLine.add(twistFrontTween, 0);

    const achievementDefer = $q.defer();
    _achievement.list({ category: 'cup', count: 1, order: '' }).then((a) => {
      $scope.cup.prizeCount = a.result[0].prize;
      $scope.cup.goal = a.result[0].progress[1];
      $scope.cup.currentCount = a.result[0].progress[0];
      $scope.cup.currentPtc = a.result[0].progress[2];
      achievementDefer.resolve();

      const progressCupConfig = {
        height: '84%',
        ease: SteppedEase.config(100),
        onComplete: () => {
          if ($scope.cup.currentPtc === 100) {
            TweenMax.to(cupElement.progress, 0.3, { height: '100%', ease: SteppedEase.config(100) });
          }
        },
      };
      const progressMarkConfig = {
        bottom: '84%',
        ease: SteppedEase.config(100),
      };
      const progressLabel = calcProgress($scope.cup.currentPtc);
      const progressCupTween = TweenMax.to(cupElement.progress, progressDuration, progressCupConfig);
      const progressMarkTween = TweenMax.to(cupElement.mark, progressDuration, progressMarkConfig);

      progressTimeLine.add(progressCupTween, 0);
      progressTimeLine.add(progressMarkTween, 0);

      progressTimeLine.addLabel('progressCurrent', progressLabel);
      progressTimeLine.addPause('progressCurrent');
    });

    const cupGoldImageDefer = $q.defer();
    const cupGoldImage = new Image();
    cupGoldImage.onload = () => {
      cupGoldImageDefer.resolve();
    };
    cupGoldImage.src = `${_config.cdn}/${_config.siteName}/cup-page/m_cup_gold.png`;

    const cupGlassImageDefer = $q.defer();
    const cupGlassImage = new Image();
    cupGlassImage.onload = () => {
      cupGlassImageDefer.resolve();
    };
    cupGlassImage.src = `${_config.cdn}/${_config.siteName}/cup-page/m_cup_glass.png`;

    $q.all([achievementDefer.promise, cupGoldImageDefer.promise, cupGlassImageDefer.promise])
      .then((a) => {
        const progressLabel = calcProgress($scope.cup.currentPtc);
        progressTimeLine.addLabel('progressCurrent', progressLabel);
        progressTimeLine.addPause('progressCurrent');
        cupElement.wrap.classList.add('linda-img-loaded');
        progressTimeLine.play(0);

        twistTimeLine.progress(0.5);
      })
      .catch((e) => {})
      .finally(() => {});

    function twistCup(e) {
      if (e.gamma <= 30 && e.gamma >= -30) {
        twistTimeLine.progress(e.gamma / 60 + 0.5);
      }
    }

    let movePosition = 0;
    let moveProgress = 0.5;

    function twistCupMove(e) {
      let d = 100;
      if (!movePosition) {
        movePosition = e.touches[0].clientX;
      }
      moveProgress += (e.touches[0].clientX - movePosition) / d;
      movePosition = e.touches[0].clientX;
      if (moveProgress > 1) {
        moveProgress = 1;
      }
      if (moveProgress < 0) {
        moveProgress = 0;
      }
      twistTimeLine.progress(moveProgress);
    }

    function calcProgress(x) {
      const deltaBottom = 0.343 * progressDuration;
      const progressLine = x * progressDuration * 0.01 * 0.657;
      return deltaBottom + progressLine;
    }

    this.$onInit = () => {
      if (_config.ios) {
        cupElement.cup.addEventListener('touchmove', twistCupMove);
        cupElement.cup.addEventListener('touchend', () => {
          movePosition = 0;
        });
      } else {
        window.addEventListener('deviceorientation', twistCup);
      }
    };

    this.$onDestroy = () => {
      if (_config.ios) {
        cupElement.cup.removeEventListener('touchmove', twistCupMove);
        cupElement.cup.removeEventListener('touchend', () => {
          movePosition = 0;
        });
      } else {
        window.removeEventListener('deviceorientation', twistCup);
      }
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
