import '../../../service/domain/payment.js';
import '../../../service/helper.js';
import '../../../service/domain/modal.js';
(function () {
  'use strict';

  const component = { name: 'lindaQuickDeposit' };

  controller.$inject = ['$scope', 'payment', 'helper', '$timeout', 'modal'];

  function controller($scope, _payment, _helper, $timeout, _modal) {
    $scope.preloader = false;
    $scope.isQuickDeposit = false;
    let defaultAmount = 0;

    /***
     *
     *      depositStatus - орієнтир для блоку результату депозиту
     *
     *          empty - блок прихований
     *          processing - заявка на транзакцію обробляється
     *          success - депозит успішний (перевірити чи гроші впали на рахунок)
     *          rejected - депозит не успішний
     *
     * ***/

    $scope.depositStatus = 'empty'; // 'processing', 'success', 'rejected'

    $scope.cards = [];
    $scope.selectedCard = {};
    $scope.paysystem = {};

    $scope.formData = {
      paymethod: '',
      amount: 0,
      type: 'OLD_CARD',
      cardAccount: '',
    };

    function createCrdCollection(cards) {
      const result = [];
      cards.forEach((card) => {
        result.push({
          cardAccount: card,
          type: card[0] == '5' ? 'mastercard' : 'visa',
          text: card.substr(-5),
        });
      });
      return result;
    }

    $scope.selectCard = function (cardIndex) {
      $scope.selectedCard = $scope.cards[cardIndex];
      $scope.formData.cardAccount = $scope.cards[cardIndex].cardAccount;
    };

    this.$onInit = function () {
      _payment
        .lastDep()
        .then((a) => {
          $scope.cards = createCrdCollection(a.result.cards);
          if ($scope.cards.length) {
            $scope.formData.amount = a.result.amount;
            defaultAmount = a.result.amount;
            $scope.formData.paymethod = a.result.paymethod;
            $scope.selectCard(0);
            $scope.paysystem = a.result.paysystem;
            $scope.paysystem.currency = a.result.currency;

            $timeout(() => {
              $scope.isQuickDeposit = true;
            }, 20);
          }
        })
        .catch((e) => {});

      $scope.$watch('quickdep.amount.$viewValue', (newVal, oldVal) => {
        if (+newVal) {
          $scope.formData.amount = newVal * 1;
        } else {
          $scope.formData.amount = 0;
        }
      });
    };

    $scope.submit = _helper.form.validator((form) => {
      $scope.preloader = true;
      $scope.depositStatus = 'processing';

      _payment
        .makeLastDevcodeDep($scope.formData)
        .then((a) => {
          if (typeof a.result.redirectOutput === 'object') {
            _modal.open('3ds', a.result).then(
              () => {
                $scope.depositStatus = 'success';
                defaultAmount = $scope.formData.amount;
              },
              () => {
                $scope.depositStatus = 'rejected';
                $scope.formData.amount = defaultAmount;
              }
            );
          } else if (a.result.transactionId) {
            $scope.depositStatus = 'success';
          }
        })
        .catch((e) => {
          $scope.depositStatus = 'rejected';
        })
        .finally(() => {
          $scope.preloader = false;
        });
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
