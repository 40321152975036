(function () {
  'use strict';
  const directive = { name: 'scrollToElement' };

  controller.$inject = [];

  function controller() {
    function link(scope, element, attrs) {
      element[0].scrollIntoView({
        behavior: 'smooth',
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
