import user from "../../app/service/domain/user.js"

(function(){

  'use strict';

  const directive = { name: 'disableWithdrawalList' };

  controller.$inject = ['user'];

  function controller(_user){

    function link( scope ){

      const withdrawalList = document.querySelector('#payments-withdrawal-list');

      if (withdrawalList) {
        scope.$watch('collection', function() {
          if (scope.collection.length > 2) {
            withdrawalList.classList.add('disabled');
          } else if (_user.profile.balance < 10) {
            withdrawalList.classList.add('disabled');
          } else {
            withdrawalList.classList.remove('disabled');
          }
        });
      }
    }

    return {
      restrict: 'A',
      link: link
    };
  }

  app.directive( directive.name, controller );
})();

